import Swal from "sweetalert2";
import i18n from "../../i18n/i18n";

const sucesso = (titulo, texto, textoBotao) => {
  return Swal.fire({
    title: titulo ?? i18n.t("GERAL.SUCCESSO"),
    text: texto ?? i18n.t("GERAL.ACAO_CONFIRMADA"),
    icon: "success",
    confirmButtonText: textoBotao ?? i18n.t("GERAL.OK"),
  });
};

const erro = (titulo, texto, textoBotao) => {
  return Swal.fire({
    title: titulo ?? i18n.t("GERAL.ERRO"),
    text: texto ?? i18n.t("GERAL.ACAO_ERRO"),
    icon: "error",
    confirmButtonText: textoBotao ?? i18n.t("GERAL.OK"),
  });
};

const warning = (titulo, texto, textoBotao) => {
  return Swal.fire({
    title: titulo ?? i18n.t("GERAL.AVISO"),
    text: texto ?? i18n.t("GERAL.ACAO_AVISO"),
    icon: "warning",
    confirmButtonText: textoBotao ?? i18n.t("GERAL.OK"),
  });
};

const confirmacao = async (titulo, html) => {
  const customSwal = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-outline-danger",
    },
    buttonsStyling: false,
  });

  return await customSwal.fire({
    title: titulo ?? i18n.t("GERAL.CONFIRMAR"),
    html: html ?? i18n.t("GERAL.MENSAGEM_CONFIRMAR_ACAO"),
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: i18n.t("GERAL.SIM"),
    cancelButtonText: i18n.t("GERAL.NAO"),
  });
};

const showErrors = (err) => {
  if (err?.response?.data?.errors?.length) {
    err.response.data.errors.forEach(element => {
      element.tipoNotificacao === 'Erro' ||
      element.tipoNotificacao === null
        ? erro(i18n.t('GERAL.ERRO'), element.mensagem)
        : warning(i18n.t('GERAL.AVISO'), element.mensagem);
    });
  } else {
    erro(i18n.t('GERAL.OCORREU_UM_ERRO_INTERNO'));
  }
}

export default {
  sucesso,
  erro,
  confirmacao,
  warning,
  showErrors
};
